import { Skeleton, Stack } from "@mui/material";

interface ReferralCardSkeletonProps {
  count: number;
  height: {
    title?: number;
    body: number;
  };
}

export function ReferralCardSkeleton(props: ReferralCardSkeletonProps) {
  const { count, height } = props;
  const skeletonReferenceIndices = Array.from({ length: count }, (_, index) => index + 1);
  return (
    <Stack spacing={2}>
      {height.title ? (
        <Skeleton animation="wave" variant="rounded" height={height.title} width="60%" />
      ) : undefined}
      {skeletonReferenceIndices.map((skeletonReference) => {
        return (
          <Skeleton
            key={skeletonReference}
            sx={{
              background: (theme) =>
                `linear-gradient(90deg, ${theme.palette.info.dark} 0%, ${theme.palette.info.light}60 100%)`,
            }}
            animation="wave"
            variant="rounded"
            height={height.body}
          />
        );
      })}
    </Stack>
  );
}
